<template>
    <div>
       <v-card
            v-if="installApp && show"
            elevation="10">
            <v-card-title>הוספה למסך הבית</v-card-title>
            <v-card-text class="mt-n2">לחצו כאן להוספת האפליקציה למסך הבית</v-card-text>
            <v-card-actions class="mt-n5">
                <v-spacer/>
                <v-btn
                    color="black"
                    @click="closePopup"
                    text>
                    סגירה
                </v-btn>

                <v-btn
                    class="font-weight-bold"
                    color="primary"
                    @click="install"
                    text>
                    שמור במסך הבית
                </v-btn>
            </v-card-actions>
        </v-card> 
    </div>
</template>

<script>

export default {
    data() {
        return {
            show: true,
            installApp: null,
            registration: null
        }
    },
    methods:{
        async install() {
            this.installApp.prompt();
        },
        updateAvailable(event) {
            this.registration = event.detail
            this.refreshApp()
        },
        refreshApp() {
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!this.registration || !this.registration.waiting) return
            // Send message to SW to skip the waiting and activate the new SW
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        },
        closePopup() {
            this.show = false
        }
    },
    created() {
        //Catch install event before prompt and save on 'installApp' variable
        window.addEventListener("beforeinstallprompt", e => {
            e.preventDefault();
            // Stash the event so it can be triggered later.
            this.installApp = e;
        });
        //Called when app installed
        window.addEventListener("appinstalled", () => {
            this.installApp = null;
            this.show = false
        });
        //Event listener for update - run once and remove listener
        document.addEventListener('swUpdated', this.updateAvailable, { once: true })
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            // We'll also need to add 'refreshing' to our data originally set to false.
            if (this.refreshing) return
            this.refreshing = true
            // Here the actual reload of the page occurs
            window.location.reload()
        })
    }
}

</script>

<style scoped>


</style>